import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';

const Fehler404 = props => (
  
    <Layout>
        <h2>Da ging leider etwas schief. Keine rocks hier.</h2>

    </Layout>

);

export default Fehler404;